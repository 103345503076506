import React, { Component } from 'react'

export class Gallery extends Component {
  render() {
    return (
      <div id="projects" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Projects</h2>
            <p>The products we develop and the services we do.</p>
          </div>
          <div className="row">
            <div className="projects-items">
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="projects-item">
                  <div className="hover-bg">
                    <div className="hover-text">
                      <h4>diXri</h4>
                    </div>
                  <img src="img/projects/06-small.png" className="img-responsive" alt="Project Title"/> </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="projects-item">
                  <div className="hover-bg">
                    <div className="hover-text">
                      <h4>CheckupBOX</h4>
                    </div>
                    <img src="img/projects/02-small.jpg" className="img-responsive" alt="Project Title"/> </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="projects-item">
                  <div className="hover-bg">
                    <div className="hover-text">
                      <h4>Travel Activity</h4>
                    </div>
                    <img src="img/projects/04-small.jpg" className="img-responsive" alt="Project Title"/> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Gallery
