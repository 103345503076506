import React, { Component } from 'react'
import $ from 'jquery';

export class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resumeData: null
    }
  }
  getResumeData() {
    $.ajax({
      url: '/data.json',
      dataType: 'json',
      cache: false,
      success: function(data) {
        this.setState({
          resumeData: data
        });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }
  componentDidMount() {
    this.getResumeData();
  }
  render() {
    return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>{this.state.resumeData ? this.state.resumeData.Privacy.title : 'Loading'}<span></span></h1>
                  <p>{this.state.resumeData ? this.state.resumeData.Privacy.text : 'Loading'}</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Privacy
