import React, { Component } from 'react'

export class Navigation extends Component {


  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1"> <span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span> <span className="icon-bar"></span> <span className="icon-bar"></span> </button>
            <img className="logo" src="logo.png" alt="DR logo" />
            <a className="navbar-brand page-scroll" href="/">Digrise</a> </div>
          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul className="nav navbar-nav navbar-right">
              <li><a href="#about" className="page-scroll">About</a></li>
              <li><a href="#services" className="page-scroll">Services</a></li>
              <li><a href="#projects" className="page-scroll">Projects</a></li>
              <li><a href="#contact" className="page-scroll">Contact</a></li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
//
// <li>
// <button type="button" style={{
// 'border': "white",
// 'color': "#1eb5ed",
// 'font-size': "14px",
// 'background-color': 'white',
// }}>EN/TR</button>
// </li>

export default Navigation
