import React, { Component } from 'react'
import axios from 'axios';
import { Link } from "react-router-dom";

export class Contact extends Component {
  constructor(props) {
    super();
    var date = new Date();
    var year = date.getFullYear();
    this.state = {
      year: year,
      name: '',
      message: '',
      email: '',
      sent: false,
      buttonText: 'Send Message',
      sentSuccess: '',
      submitted: false,
      submitting: false,
      info: {
        error: false,
        msg: null
      }
    }
  }


  formSubmit = (e) => {
    e.preventDefault()
    this.setState({
      buttonText: '...sending',
      submitting: true,
      submitted: false
    })

    let data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message
    }
    axios({
      method: 'POST',
      url: 'https://formspree.io/f/mjvpednv',
      data: data,
    }).then(res => {
      this.setState({
        sent: true,
        submitted: true,
        submitting: false,
        info: {
          error: false,
          msg: 'Thank you, your message has been submitted.'
        },
      }, this.resetForm())
    })
      .catch((e) => {
        console.log('Message not sent ' + e)
        this.setState({
          info: {
            error: true,
            msg: "Message didn't Send"
          },
          submitted: false,
          submitting: false,
          buttonText: 'Send Message',
          sentSuccess: "Message didn't Send"
        })
      })
  }

  resetForm = () => {
    this.setState({
      name: '',
      message: '',
      email: '',
      buttonText: 'Send Message',
      sentSuccess: 'Message Sent'
    })
  }

  render() {
    return (
      <div>
      <div id="contact">
  <div className="container">
    <div className="col-md-8">
      <div className="row">
        <div className="section-title">
          <h2>Contact Us</h2>
          <p>We look forward to realizing your excellent projects and ideas. Contact us immediately.</p>
        </div>
        <form name="sentMessage" id="contactForm" onSubmit={ (e) => this.formSubmit(e)}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input type="text" id="name" name="_replyto" className="form-control" placeholder="Name" required="required"  onChange={e => this.setState({
        name: e.target.value
      })}  value={this.state.name}/>
                <p className="help-block text-danger"></p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input type="email" id="email" name="email" className="form-control" placeholder="Email" required="required"  onChange={e => this.setState({
        email: e.target.value
      })}  value={this.state.email}/>
                <p className="help-block text-danger"></p>
              </div>
            </div>
          </div>
          <div className="form-group">
            <textarea type="message" id="message" name="message" className="form-control" rows="4" placeholder="Message" required="required" onChange={e => this.setState({
        message: e.target.value
      })}  value={this.state.message}></textarea>
            <p className="help-block text-danger"></p>
          </div>
          <div id="success"></div>
          <button type="submit" className="btn btn-custom btn-lg" disabled={this.state.submitting}>
          {!this.state.submitting
        ? !this.state.submitted
          ? 'Submit'
          : 'Submitted'
        : 'Submitting...'}</button>
      {this.state.info.error && (
      <div className="error">Error: {this.state.info.msg}</div>
      )}
      {!this.state.info.error && this.state.info.msg && <p className="text-default">{this.state.info.msg}</p>}
        </form>
      </div>
    </div>
    <div className="col-md-3 col-md-offset-1 contact-info">
      <div className="contact-item">
        <h3>Contact Information</h3>
        <p><span><i className="fa fa-map-marker"></i> Address</span>{this.props.data ? this.props.data.address : 'loading'}</p>
      </div>
      <div className="contact-item">
        <p><span><i className="fa fa-phone"></i> Phone</span> {this.props.data ? this.props.data.phone : 'loading'}</p>
      </div>
      <div className="contact-item">
        <p><span><i className="fa fa-envelope-o"></i> Email</span> {this.props.data ? this.props.data.email : 'loading'}</p>
      </div>
    </div>
    <div className="col-md-12">
      <div className="row">
        <div className="social">
          <ul>
            <li><a href={this.props.data ? this.props.data.facebook : '/'} target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a></li>
            <li><a href={this.props.data ? this.props.data.twitter : '/'} target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a></li>
            <li><a href={this.props.data ? this.props.data.instagram : '/'} target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
            <li><a href={this.props.data ? this.props.data.github : '/'} target="_blank" rel="noopener noreferrer"><i className="fa fa-github"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="footer">
  <div className="container text-center">
    <span><Link to="/privacy"> Privacy </Link></span>
    <span>© {this.state.year} <a href="http://www.digrise.com" rel="nofollow"> Digrise </a></span>
  </div>
</div>
      </div>
    )
  }
}

export default Contact
