import React from 'react'

import { BrowserRouter as Router, Route } from "react-router-dom";
import App from '../screen/App';
import Privacy from '../screen/Privacy';
const AppRouter = () => (
  <Router>
  <div>
        <Route path="/"  exact component={App} />
        <Route path="/privacy"  exact component={Privacy} />
        </div>
    </Router>
);

export default AppRouter;
