import React, { Component } from 'react'
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
const fadeImages = [
  {
    img: 'img/img-12.jpg',
  },
  {
    img: 'img/img-6.jpg',
  },
  {
    img: 'img/img-14.jpg',
  },
  {
    img: 'img/img-7.jpg',
  },
  {
    img: 'img/img-8.jpg',
  }
];

const fadeProperties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true
}


export class Header extends Component {
  render() {
    return (
      <div className="slide-container">
        <Fade {...fadeProperties}>
        {fadeImages.map(function(item, i) {
        return (
          <div className="each-fade">
          <img src={item.img} alt="Slide logo" style={{
            width: '100%'
          }}/>
          </div>
        )
      })}
  </Fade>
        </div>
    )
  }
}

export default Header
